@if (info()) {
  <ul class="contact-info flex-auto text-left">
    @if (info().fullAddress) {
      <li>
        <i class="material-icons text-{{ color() }}">location_on</i>
        <p class="m-0 ml-2">
          {{ info().fullAddress | translate }}
        </p>
      </li>
    }
    @if (businessEntity()) {
      <li>
        <i class="material-icons text-{{ color() }}">person</i>
        <p class="m-0 ml-2">
          {{ businessEntity() | translate }}
        </p>
      </li>
    }
    @if (info().email) {
      <li>
        <i class="material-icons text-{{ color() }}">email</i>
        <p class="m-0 ml-2">
          {{ info().email }}
        </p>
      </li>
    }
    @if (info().homeNumber) {
      <li>
        <i class="material-icons text-{{ color() }}">home</i>
        <p class="m-0 ml-2 h3 text-accent">{{ info().homeNumber }}</p>
      </li>
    }
    @if (info().phoneNumber) {
      <li>
        <i class="material-icons text-{{ color() }}">phone</i>
        <p class="m-0 ml-2 h3 text-accent">{{ info().phoneNumber }}</p>
      </li>
    }
  </ul>
}
