import { Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { constants } from '../../../../../environments/constants';
import { input } from '@angular/core';

@Component({
  selector: 'app-about-info',
  templateUrl: './about-info.component.html',
  styleUrls: ['./about-info.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class AboutInfoComponent {
  rootData = constants.rootData;

  color = input<string | undefined>();
  businessEntity = input<string | undefined>();
  info = input(this.rootData.info);
}
